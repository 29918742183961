import request from '@/utils/request2'

// 获取需要本人评价的360考核表列表接口
export function getUser360CheckList(params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getUser360CheckList',
    method: 'get',
    params: { empid }
  })
}

// 获取单笔或多笔360评价表详细信息接口
export function getEmp360PaperDetail(data) {
  return request({
    url: '/api/wxjx/getEmp360PaperDetail',
    method: 'post',
    data: data
  })
}

// 获取本人的360评价人信息列表
export function getUser360PjrList(params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getUser360PjrList',
    method: 'get',
    params: { empid }
  })
}

// 同意360评价人信息
export function agreeUser360Pjr(params) {
  const { autoid, username } = params
  return request({
    url: '/api/wxjx/agreeUser360Pjr',
    method: 'get',
    params: { autoid, username }
  })
}

// 不同意360评价人信息
export function notAgreeUser360Pjr(params) {
  const { autoid, username, notagreereason } = params
  return request({
    url: '/api/wxjx/notAgreeUser360Pjr',
    method: 'get',
    params: { autoid, username, notagreereason }
  })
}

// 保存单笔或多笔360评价表详细信息接口
export function saveEmp360PaperDetail(data) {
  return request({
    url: '/api/wxjx/saveEmp360PaperDetail',
    method: 'post',
    data: data
  })
}

// 获取“我的360指标”记录列表（倒序排列）
export function getMy360KhList(params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getMy360KhList',
    method: 'get',
    params: { empid }
  })
}
// 获取360某个周期的考核指标
export function getMy360IndicatorList_ForKhTime(params) {
  const { empid, khtimeid } = params
  return request({
    url: '/api/wxjx/getMy360IndicatorList_ForKhTime',
    method: 'get',
    params: { empid, khtimeid }
  })
}

// 7.3.查看某个“360指标方案”所有指标明细接口
export function getMy360IndicatorList(data) {
  return request({
    url: '/api/wxjx/getMy360IndicatorList',
    method: 'get',
    params: data
  })
}

// 7.4.查看某个“360指标方案”的单个指标详细信息接口
export function getMy360IndicatorDetail(data) {
  return request({
    url: '/api/wxjx/getMy360IndicatorDetail',
    method: 'get',
    params: data
  })
}

// 7.5.修改某个“360指标方案”的单个指标详细信息接口
export function saveMy360Indicator(data) {
  return request({
    url: '/api/wxjx/saveMy360Indicator',
    method: 'post',
    data: data
  })
}

// 7.6.新增某个“360指标方案”的单个指标详细信息接
export function addMy360Indicator(data) {
  return request({
    url: '/api/wxjx/addMy360Indicator',
    method: 'post',
    data: data
  })
}

// 7.7.删除某个“360指标方案”的单个指标详细信息接口
export function deleteMy360Indicator(data) {
  return request({
    url: '/api/wxjx/deleteMy360Indicator',
    method: 'get',
    params: data
  })
}

// 7.8.提交“360指标方案”接口
export function postMy360PlanIndicator(data) {
  return request({
    url: '/api/wxjx/postMy360PlanIndicator',
    method: 'get',
    params: data
  })
}

// 7.9.获取“我的KPI指标”记录列表（倒序排列）
export function getMyKpiKhList(params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getMyKpiKhList',
    method: 'get',
    params: { empid }
  })
}
// C.获取KPI某个周期的考核指标
export function getMyKpiIndicatorList_ForKhTime(params) {
  const { empid, khtimeid } = params
  return request({
    url: '/api/wxjx/getMyKpiIndicatorList_ForKhTime',
    method: 'get',
    params: { empid, khtimeid }
  })
}
// 点击复制指标调用接口：
export function copyLastTimeIndicator(params) {
  const { empid, khtimeid, flag } = params
  return request({
    url: '/api/wxjx/copyLastTimeIndicator',
    method: 'get',
    params: { empid, khtimeid, flag }
  })
}
// 我的考核指标催办接口
export function urgeMyIndicator(params) {
  const { empid, khtimeid, flag } = params
  return request({
    url: '/api/wxjx/urgeMyIndicator',
    method: 'get',
    params: { empid, khtimeid, flag }
  })
}

// 7.10.查看某个“KPI指标方案”所有指标明细接口
export function getMyKpiIndicatorList(data) {
  return request({
    url: '/api/wxjx/getMyKpiIndicatorList',
    method: 'get',
    params: data
  })
}

// 7.11.查看某个“KPI指标方案”的单个指标详细信息接口
export function getMyKpiIndicatorDetail(data) {
  return request({
    url: '/api/wxjx/getMyKpiIndicatorDetail',
    method: 'get',
    params: data
  })
}

// 7.12.修改某个“KPI指标方案”的单个指标详细信息接口
export function saveMyKpiIndicator(data) {
  return request({
    url: '/api/wxjx/saveMyKpiIndicator',
    method: 'post',
    data: data
  })
}

// 7.13.新增某个“KPI指标方案”的单个指标详细信息接口
export function addMyKpiIndicator(data) {
  return request({
    url: '/api/wxjx/addMyKpiIndicator',
    method: 'post',
    data: data
  })
}

// 7.14.删除某个“KPI指标方案”的单个指标详细信息接口
export function deleteMyKpiIndicator(data) {
  return request({
    url: '/api/wxjx/deleteMyKpiIndicator',
    method: 'get',
    params: data
  })
}

// 7.15.提交“KPI指标方案”接口
export function postMyKpiPlanIndicator(data) {
  return request({
    url: '/api/wxjx/postMyKpiPlanIndicator',
    method: 'get',
    params: data
  })
}

// 9.1.获取待审批的360考核方案列表
export function getMy360ForCheckList(data) {
  return request({
    url: '/api/wxjx/getMy360ForCheckList',
    method: 'get',
    params: data
  })
}
// A.获取某个周期下360考核需审批的方案列表
export function getMy360ForCheckList_ForKhTime(data) {
  return request({
    url: '/api/wxjx/getMy360ForCheckList_ForKhTime',
    method: 'get',
    params: data
  })
}

// 9.3.同意或不同意360考核方案接口
export function agreeMy360KhIndicator(data) {
  return request({
    url: '/api/wxjx/agreeMy360KhIndicator',
    method: 'get',
    params: data
  })
}

// 9.6.同意或不同意KPI考核方案接口
export function agreeMyKpiKhIndicator(data) {
  return request({
    url: '/api/wxjx/agreeMyKpiKhIndicator',
    method: 'get',
    params: data
  })
}

// 9.4.获取待审批的KPI考核方案列表
export function getMyKpiForCheckList(data) {
  return request({
    url: '/api/wxjx/getMyKpiForCheckList',
    method: 'get',
    params: data
  })
}
// B.获取某个周期下KPI考核需审批的方案列表
export function getMyKpiForCheckList_ForKhTime(data) {
  return request({
    url: '/api/wxjx/getMyKpiForCheckList_ForKhTime',
    method: 'get',
    params: data
  })
}

// 10.1.获取待评价的KPI方案记录
export function getKpiPlanCheckList(data) {
  return request({
    url: '/api/wxjx/getKpiPlanCheckList',
    method: 'get',
    params: data
  })
}
// 我的考核结果里的分数和等级确认操作
export function myKhScoreAndLevelCheck(data) {
  return request({
    url: '/api/wxjx/myKhScoreAndLevelCheck',
    method: 'get',
    params: data
  })
}

// 8.1.获取我的历史360考核方案列表
export function getMy360HisRecList(data) {
  return request({
    url: '/api/wxjx/getMy360HisRecList',
    method: 'get',
    params: data
  })
}

// 10.2.获取某个待评价的KPI方案明细数据接口
export function getKpiPlanIndicatorDetail(data) {
  return request({
    url: '/api/wxjx/getKpiPlanIndicatorDetail',
    method: 'get',
    params: data
  })
}

// 8.2.获取我的历史KPI考核方案列表
export function getMyKpiHisRecList(data) {
  return request({
    url: '/api/wxjx/getMyKpiHisRecList',
    method: 'get',
    params: data
  })
}

// 8.3.查看我的某个360考核方案明细接口
export function getMy360HisRecDetail(data) {
  return request({
    url: '/api/wxjx/getMy360HisRecDetail',
    method: 'get',
    params: data
  })
}

// 8.4.查看我的某个KPI考核方案明细接口
export function getMyKpiHisRecDetail(data) {
  return request({
    url: '/api/wxjx/getMyKpiHisRecDetail',
    method: 'get',
    params: data
  })
}
// 保存待评价的KPI方案明细数据接口（批量保存）
export function saveKpiPlanIndicatorDetail(data) {
  return request({
    url: '/api/wxjx/saveKpiPlanIndicatorDetail',
    method: 'post',
    data: data
  })
}

// 获取首页绩效平台相关数据
export function getJxCount(params) {
  const { empid } = params
  return request({
    url: '/api/wxjx/getJxCount',
    method: 'get',
    params: { empid }
  })
}

// 360指标的指标类别下拉选项
export function getMy360IndicatorTypeList(data) {
  return request({
    url: '/api/wxjx/getMy360IndicatorTypeList',
    method: 'get',
    params: data
  })
}

// KPI指标的指标类别下拉选项
export function getMyKpiIndicatorTypeList(data) {
  return request({
    url: '/api/wxjx/getMyKpiIndicatorTypeList',
    method: 'get',
    params: data
  })
}

// 获取通用树结构
export function getCommTreeData(data) {
  return request({
    url: '/api/wx/getCommTreeData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 13.1获取申诉界面详细信息接口
export function getSsDetail(data) {
  return request({
    url: '/api/wxjx/getSsDetail',
    method: 'get',
    params: data
  })
}

// 13.2获取期望等级下拉列表接口
export function getJxLevel(data) {
  return request({
    url: '/api/wxjx/getJxLevel',
    method: 'get',
    params: data
  })
}

// 13.3提交绩效申诉内容接口
export function postJxSs(data) {
  return request({
    url: '/api/wxjx/postJxSs',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

//13.4获取“我的申诉”列表接口
export function getMySsList(data) {
  return request({
    url: '/api/wxjx/getMySsList',
    method: 'get',
    params: data
  })
}

// 13.5获取“下级申诉”列表接口
export function getXjSsList(data) {
  return request({
    url: '/api/wxjx/getXjSsList',
    method: 'get',
    params: data
  })
}

// 13.6同意或否决下级申诉的接口
export function postXjSsInfo(data) {
  return request({
    url: '/api/wxjx/postXjSsInfo',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 14.1获取“我的面谈”列表接口
export function getMyMtList(data) {
  return request({
    url: '/api/wxjx/getMyMtList',
    method: 'get',
    params: data
  })
}

// 14.2获取面谈详细信息接口
export function getMtDetail(data) {
  return request({
    url: '/api/wxjx/getMtDetail',
    method: 'get',
    params: data
  })
}

// 14.3提交本人面谈详细信息接口
export function postMyMtDetail(data) {
  return request({
    url: '/api/wxjx/postMyMtDetail',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 14.4获取“下级面谈评价”列表接口
export function getXjMtList(data) {
  return request({
    url: '/api/wxjx/getXjMtList',
    method: 'get',
    params: data
  })
}

// 14.5提交下级面谈详细信息接口
export function postXjMtDetail(data) {
  return request({
    url: '/api/wxjx/postXjMtDetail',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

// 15.1获取“公共统计数据”所有考核周期列表接口
export function getPubCiTimeList(data) {
  return request({
    url: '/api/wxjx/getPubCiTimeList',
    method: 'get',
    params: data
  })
}

// 15.6获取“个人统计数据”所有考核周期列表接口
export function getPerCiTimeList(data) {
  return request({
    url: '/api/wxjx/getPerCiTimeList',
    method: 'get',
    params: data
  })
}

// 15.3获取某个考核周期的“公共统计数据”列表接口
export function getPubCiList(data) {
  return request({
    url: '/api/wxjx/getPubCiList',
    method: 'get',
    params: data
  })
}

// 15.5删除单笔“公共统计数据”记录明细接口
export function deletePubCiRecord(data) {
  return request({
    url: '/api/wxjx/deletePubCiRecord',
    method: 'get',
    params: data
  })
}

// 15.3获取某个“公共统计数据”记录明细接口
export function getPubCiDetail(data) {
  return request({
    url: '/api/wxjx/getPubCiDetail',
    method: 'get',
    params: data
  })
}

// 15.4新增保存单笔“公共统计数据”记录明细接口
export function addPubCiDetail(data) {
  return request({
    url: '/api/wxjx/addPubCiDetail',
    method: 'post',
    data: data
  })
}

// 15.7获取某个考核周期的“个人统计数据”列表接口
export function getPerCiList(data) {
  return request({
    url: '/api/wxjx/getPerCiList',
    method: 'get',
    params: data
  })
}

// 15.11删除单笔“个人统计数据”记录明细接口
export function deletePerCiRecord(data) {
  return request({
    url: '/api/wxjx/deletePerCiRecord',
    method: 'get',
    params: data
  })
}

// 15.9新增保存单笔“个人统计数据”记录明细接口
export function addPerCiDetail(data) {
  return request({
    url: '/api/wxjx/addPerCiDetail',
    method: 'post',
    data: data
  })
}

// 15.8获取某个“个人统计数据”记录明细接口
export function getPerCiDetail(data) {
  return request({
    url: '/api/wxjx/getPerCiDetail',
    method: 'get',
    params: data
  })
}

// 15.10修改保存单笔“个人统计数据”记录明细接口
export function savePerCiDetail(data) {
  return request({
    url: '/api/wxjx/savePerCiDetail',
    method: 'post',
    data: data
  })
}

// 修改保存单笔“公共统计数据”记录明细接口
export function savePubCiDetail(data) {
  return request({
    url: '/api/wxjx/savePubCiDetail',
    method: 'post',
    data: data
  })
}

// 获取我的KPI指标新增默认值接口
export function getMyKpiNewDefaultValue(data) {
  return request({
    url: '/api/wxjx/getMyKpiNewDefaultValue',
    method: 'get',
    params: data
  })
}

// 获取某个kpi总分
export function getMyKpiTotalScore(data) {
  return request({
    url: '/api/wxjx/getMyKpiTotalScore',
    method: 'get',
    params: data
  })
}

// 确认绩效申诉
export function qrJxSs(data) {
  return request({
    url: '/api/wxjx/qrJxSs',
    method: 'get',
    params: data
  })
}

// 上传文件的base64
export function upLoadBase64FileFixName(data) {
  return request({
    url: '/api/wx/upLoadBase64FileFixName',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

export function deleteFile (data) {
  const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
  data.username = userInfo.username
  return request({
    url: '/api/file/deleteFile',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data: data
  })
}

// 获取“指标权重”下拉列表
export function getIndicatorPerList(data) {
  return request({
    url: '/api/wxjx/getIndicatorPerList',
    method: 'get',
    params: data
  })
}

// 获取“评分上限”下拉列表接口
export function getTgValueList(data) {
  return request({
    url: '/api/wxjx/getTgValueList',
    method: 'get',
    params: data
  })
}

//8.5确认本人的KPI考核结果
export function empKhResCheck(data) {
  return request({
    url: '/api/wxjx/empKhResCheck',
    method: 'get',
    params: data
  })
}

// 保存直接和间接评价内容接口 saveKpiPjMemo
export function saveKpiPjMemo(data) {
  return request({
    url: '/api/wxjx/saveKpiPjMemo',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' }
  })
}

//获取某个指标类别所属的所有指标列表接口
export function getIndicatorList(data) {
  return request({
    url: '/api/wxjx/getIndicatorList',
    method: 'get',
    params: data
  })
}

// 下达和申请任务接口
export function addGsTaskList(data) {
  return request({
    url: '/api/wxjx/addGsTaskList',
    method: 'post',
    // headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

// 修改保存任务信息接口
export function saveGsTaskList(data) {
  return request({
    url: '/api/wxjx/saveGsTaskList',
    method: 'post',
    // headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

//上级重新提交任务接口
export function rePostGsTask(data) {
  return request({
    url: '/api/wxjx/rePostGsTask',
    method: 'get',
    params: data
  })
}
//上级作废下达的任务接口
export function cancelNotCheckGsTask(data) {
  return request({
    url: '/api/wxjx/cancelNotCheckGsTask',
    method: 'get',
    params: data
  })
}
//员工重新提交任务接口
export function rePostGsTaskForEmp(data) {
  return request({
    url: '/api/wxjx/rePostGsTaskForEmp',
    method: 'get',
    params: data
  })
}
//员工作废申请的任务接口
export function cancelNotSureGsTask(data) {
  return request({
    url: '/api/wxjx/cancelNotSureGsTask',
    method: 'get',
    params: data
  })
}

// 提交任务前的提示信息接口
export function beforeAddGsTaskListInfo(data) {
  return request({
    url: '/api/wxjx/beforeAddGsTaskListInfo',
    method: 'post',
    // headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

//获取待我确认列表接口
export function getMyForSureGsTaskList(data) {
  return request({
    url: '/api/wxjx/getMyForSureGsTaskList',
    method: 'get',
    params: data
  })
}
//获取待我审批列表接口
export function getMyForCheckGsTaskList(data) {
  return request({
    url: '/api/wxjx/getMyForCheckGsTaskList',
    method: 'get',
    params: data
  })
}
//获取"待审批"任务列表接口
export function getMyForDeAgreeGsTaskList(data) {
  return request({
    url: '/api/wxjx/getMyForDeAgreeGsTaskList',
    method: 'get',
    params: data
  })
}

//获取"被撤回"任务列表接口
export function getMyForCancelGsTaskList(data) {
  return request({
    url: '/api/wxjx/getMyForCancelGsTaskList',
    method: 'get',
    params: data
  })
}
//获取"已确认"任务列表接口
export function getMyHasSureGsTaskList(data) {
  return request({
    url: '/api/wxjx/getMyHasSureGsTaskList',
    method: 'get',
    params: data
  })
}
//获取"已审批"任务列表接口
export function getMyHasCheckGsTaskList(data) {
  return request({
    url: '/api/wxjx/getMyHasCheckGsTaskList',
    method: 'get',
    params: data
  })
}
//获取某个任务详细情况接口
export function getGsTaskRecordInfo(data) {
  return request({
    url: '/api/wxjx/getGsTaskRecordInfo',
    method: 'get',
    params: data
  })
}
//同意某个任务接口
export function checkGsTaskList(data) {
  return request({
    url: '/api/wxjx/checkGsTaskList',
    method: 'get',
    params: data
  })
}
//不同意某个任务接口
export function unCheckGsTaskList(data) {
  return request({
    url: '/api/wxjx/unCheckGsTaskList',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-datacharset=UTF-8' },
    data: data
  })
}

//"同意"或"不同意"待审批任务接口
export function agreeOrNotGsTask(data) {
  return request({
    url: '/api/wxjx/agreeOrNotGsTask',
    method: 'get',
    params: data
  })
}

//"同意"或"不同意"待确认任务接口
export function sureOrNotGsTask(data) {
  return request({
    url: '/api/wxjx/sureOrNotGsTask',
    method: 'get',
    params: data
  })
}

//获取未提交任务列表接口
export function getNotPostGsTaskList(data) {
  return request({
    url: '/api/wxjx/getNotPostGsTaskList',
    method: 'get',
    params: data
  })
}
//获取已提交任务列表接口
export function getHasPostGsTaskList(data) {
  return request({
    url: '/api/wxjx/getHasPostGsTaskList',
    method: 'get',
    params: data
  })
}
// 提交某个任务接口
export function postGsTaskList(data) {
  return request({
    url: '/api/wxjx/postGsTaskList',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}
// 提交任务接口
export function postGsTaskForDb(data) {
  return request({
    url: '/api/wxjx/postGsTaskForDb',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}
// 提交任务评分接口
export function postGsTaskPj(data) {
  return request({
    url: '/api/wxjx/postGsTaskPj',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}
// 撤回任务评分接口
export function returnGsTaskForDb(data) {
  return request({
    url: '/api/wxjx/returnGsTaskForDb',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}
// 作废任务评分接口
export function cancelDbGsTask(data) {
  return request({
    url: '/api/wxjx/cancelDbGsTask',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

//同意作废任务接口
export function sureCancelGsTask(data) {
  return request({
    url: '/api/wxjx/sureCancelGsTask',
    method: 'get',
    params: data
  })
}
//获取待评分任务列表接口
export function getForPjGsTaskList(data) {
  return request({
    url: '/api/wxjx/getForPjGsTaskList',
    method: 'get',
    params: data
  })
}
//评分同意接口
export function doPostPjGsTaskList(data) {
  return request({
    url: '/api/wxjx/doPostPjGsTaskList',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}
//评分不同意接口
export function unPostPjGsTaskList(data) {
  return request({
    url: '/api/wxjx/unPostPjGsTaskList',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

//获取我发起的任务列表接口
export function getMyReqGsTaskList(data) {
  return request({
    url: '/api/wxjx/getMyReqGsTaskList',
    method: 'get',
    params: data
  })
}
//获取我接收的任务列表接口
export function getMyReceiveGsTaskList(data) {
  return request({
    url: '/api/wxjx/getMyReceiveGsTaskList',
    method: 'get',
    params: data
  })
}
//获取我下达的任务列表接口
export function getMySendGsTaskList(data) {
  return request({
    url: '/api/wxjx/getMySendGsTaskList',
    method: 'get',
    params: data
  })
}

//判断是否具备下达任务或申请任务权限接口
export function checkHasGsTaskPostPri(data) {
  return request({
    url: '/api/wxjx/checkHasGsTaskPostPri',
    method: 'get',
    params: data
  })
}

//获取下达或申请任务各字段新增默认值接口
export function getGsTaskNewDefaultValue(data) {
  return request({
    url: '/api/wxjx/getGsTaskNewDefaultValue',
    method: 'get',
    params: data
  })
}
//获取"待提交"任务列表接口
export function getDdForPostGsTaskList(data) {
  return request({
    url: '/api/wxjx/getDdForPostGsTaskList',
    method: 'get',
    params: data
  })
}
//获取"待作废"任务列表接口
export function getForCancelGsTaskList(data) {
  return request({
    url: '/api/wxjx/getForCancelGsTaskList',
    method: 'get',
    params: data
  })
}
//获取"已撤回"任务列表接口
export function getHasReturnGsTaskList(data) {
  return request({
    url: '/api/wxjx/getHasReturnGsTaskList',
    method: 'get',
    params: data
  })
}
//获取"已作废"任务列表接口
export function getHasCancelGsTaskList(data) {
  return request({
    url: '/api/wxjx/getHasCancelGsTaskList',
    method: 'get',
    params: data
  })
}
//获取已评分列表接口
export function getHasPjGsTaskList(data) {
  return request({
    url: '/api/wxjx/getHasPjGsTaskList',
    method: 'get',
    params: data
  })
}
//查看历史评论记录接口
export function getGsTaskCommentList(data) {
  return request({
    url: '/api/wxjx/getGsTaskCommentList',
    method: 'get',
    params: data
  })
}

//提交评论接口
export function addGsTaskCommentRecord(data) {
  return request({
    url: '/api/wxjx/addGsTaskCommentRecord',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}
//查看历史汇报记录接口
export function getGsTaskReportList(data) {
  return request({
    url: '/api/wxjx/getGsTaskReportList',
    method: 'get',
    params: data
  })
}

//汇报任务接口
export function reportGsTaskForDb(data) {
  return request({
    url: '/api/wxjx/reportGsTaskForDb',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
    data
  })
}

//查看历史催办记录接口
export function getGsTaskAlarmList(data) {
  return request({
    url: '/api/wxjx/getGsTaskAlarmList',
    method: 'get',
    params: data
  })
}
//提交一笔催办接口
export function addGsTaskAlarmRecord(data) {
  return request({
    url: '/api/wxjx/addGsTaskAlarmRecord',
    method: 'get',
    params: data
  })
}
//验收人评分数据带出过程
export function getGsPjScoreExitValue(data) {
  return request({
    url: '/api/wxjx/getGsPjScoreExitValue',
    method: 'get',
    params: data
  })
}

// 检测用户是否有某个模块的权限（针对系统导航里面的模块跳转权限）
export function checkFunPower(params) {
  return request({
    url: '/api/baseform/checkFunPower',
    method: 'get',
    params: params
  })
}

// 获取所有考核周期列表
export function getKhTimeList(params) {
  return request({
    url: '/api/wxjx/getKhTimeList',
    method: 'get',
    params: params
  })
}
// 获取某个考核周期下KPI所有考核指标明细接口
export function get_My_Kpi_HisRecDetail_ForKhTime(params) {
  return request({
    url: '/api/wxjx/get_My_Kpi_HisRecDetail_ForKhTime',
    method: 'get',
    params: params
  })
}
// 获取某个考核周期下360所有考核指标明细接口
export function get_My_360_HisRecDetail_ForKhTime(params) {
  return request({
    url: '/api/wxjx/get_My_360_HisRecDetail_ForKhTime',
    method: 'get',
    params: params
  })
}
// 获取某个考核周期下GS所有任务指标明细接口
export function get_My_Gs_HisRecDetail_ForKhTime(params) {
  return request({
    url: '/api/wxjx/get_My_Gs_HisRecDetail_ForKhTime',
    method: 'get',
    params: params
  })
}

// 获取GS任务考核周期列表
export function getMyGsTaskKhTime(params) {
  return request({
    url: '/api/wxjx/getMyGsTaskKhTime',
    method: 'get',
    params: params
  })
}

//获取我的GS考核任务列表
export function getMyGsTaskList(params) {
  return request({
    url: '/api/wxjx/getMyGsTaskList',
    method: 'get',
    params: params
  })
}

//确认我的GS考核任务接口
export function checkMyTask(params) {
  return request({
    url: '/api/wxjx/checkMyTask',
    method: 'get',
    params: params
  })
}
//我的GS任务催办接口
export function urgeMyGsTask(params) {
         return request({
           url: '/api/wxjx/urgeMyGsTask',
           method: 'get',
           params: params
         })
       }
//保存360绩效特殊加减分接口
export function save360SpecScore(params) {
         return request({
           url: '/api/wxjx/save360SpecScore',
           method: 'get',
           params: params
         })
       }
//获取360待审批指标的人员列表接口
export function get360ForCheckEmpList(params) {
         return request({
           url: '/api/wxjx/get360ForCheckEmpList',
           method: 'get',
           params: params
         })
       }
//获取某个360待审批指标明细接口
export function getMy360ForCheckList_GroupDetail(params) {
         return request({
           url: '/api/wxjx/getMy360ForCheckList_GroupDetail',
           method: 'get',
           params: params
         })
       }
//获取KPI待审批指标的人员列表接口
export function getKPIForCheckEmpList(params) {
         return request({
           url: '/api/wxjx/getKPIForCheckEmpList',
           method: 'get',
           params: params
         })
       }
//获取某个KPI待审批指标明细接口
export function getMyKPIForCheckList_GroupDetail(params) {
         return request({
           url: '/api/wxjx/getMyKPIForCheckList_GroupDetail',
           method: 'get',
           params: params
         })
       }
