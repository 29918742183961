<template>
  <div class="My360ApplicationDetails">
    <!-- 360/KPI添加考核申请 -->
    {{ this.$route.query.from }}
    <van-nav-bar
      :title="$t('jxMyAssTargetDetails.txt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div class="content" :style="canSave ? '' : 'height:100%;'">
      <van-form>
        <!-- <van-field v-model="form.sqnum"
                   label="排序号"
                   :readonly="canreadonly"
                   input-align="right"
                   placeholder="排序号" /> -->
        <van-cell
          class="sqnum_cell"
          v-if="$t('jxMyAssTargetDetails.txt2')"
          :title="$t('jxMyAssTargetDetails.txt2')"
        >
          <template #default>
            <van-stepper v-model="form.sqnum" step="1" />
          </template>
        </van-cell>
        <!-- :rules="[{ required: true, message: '请填写用户名' }]" -->
        <van-field
          v-if="$t('jxMyAssTargetDetails.txt3')"
          v-model="form.quotaType"
          :label="$t('jxMyAssTargetDetails.txt3')"
          :readonly="canTypeReadonly"
          :placeholder="$t('jxMyAssTargetDetails.txt3')"
          @click="showTypePicker"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="typeColumns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>

        <van-field
          v-if="$t('jxMyAssTargetDetails.txt4')"
          v-model="form.quotaName"
          :label="$t('jxMyAssTargetDetails.txt4')"
          :readonly="canreadonly || req_indicator_select == 'true'"
          :placeholder="$t('jxMyAssTargetDetails.txt4')"
          @click="showQuotaNamePicker"
        />
        <van-popup v-model="showNamePicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="nameColumns"
            value-key="indicatorname"
            @confirm="onNameConfirm"
            @cancel="showNamePicker = false"
          />
        </van-popup>

        <van-field
          v-if="showWeight && $t('jxMyAssTargetDetails.txt5')"
          v-model="form.weight"
          type="number"
          :disabled="canreadonly"
          :label="$t('jxMyAssTargetDetails.txt5')"
        >
          <template #right-icon>
            <van-icon name="edit" @click="showWeightPop" />
          </template>
        </van-field>
        <van-popup v-model="showWeightPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="WeightTypeColumns"
            @confirm="onWeightTypeConfirm"
            @cancel="showWeightPicker = false"
          />
        </van-popup>
        <!-- <van-cell class="weight_cell"
                  v-if="showWeight && $t('jxMyAssTargetDetails.txt5')"
                  :title="$t('jxMyAssTargetDetails.txt5')">
          <template #default>
            <van-stepper v-model="form.weight"
                         :disabled="canreadonly"
                         step="5" />
          </template>
        </van-cell> -->
        <van-field
          v-if="
            showWeight
              ? $t('jxMyAssTargetDetails.txt6')
              : $t('jxMyAssTargetDetails.txt7')
          "
          v-model="form.score"
          type="number"
          :disabled="canreadonly"
          :label="
            showWeight
              ? $t('jxMyAssTargetDetails.txt6')
              : $t('jxMyAssTargetDetails.txt7')
          "
        >
          <template #right-icon>
            <van-icon name="edit" @click="showScorePop" />
          </template>
        </van-field>
        <van-popup v-model="showScorePicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="ScoreTypeColumns"
            @confirm="onScoreTypeConfirm"
            @cancel="showScorePicker = false"
          />
        </van-popup>

        <!-- <van-cell v-if="showWeight ? $t('jxMyAssTargetDetails.txt6') : $t('jxMyAssTargetDetails.txt7')"
                  class="score_cell"
                  :title="
            showWeight
              ? $t('jxMyAssTargetDetails.txt6')
              : $t('jxMyAssTargetDetails.txt7')
          ">
          <template #default>
            <van-stepper v-model="form.score"
                         :disabled="canreadonly"
                         step="1" />
          </template>
        </van-cell> -->
        <!-- 直接评价人工号 -->
        <van-field
          :value="
            form.zjsj_empcode ? form.zjsj_empcode + '-' + form.zjsj_empname : ''
          "
          v-if="
            showWeight &&
              usezj_forid == 'true' &&
              $t('jxMyAssTargetDetails.txt8')
          "
          :label="$t('jxMyAssTargetDetails.txt8')"
          readonly
          input-align="right"
          :placeholder="$t('jxMyAssTargetDetails.txt9')"
          @click="showEmpTreePop('直接评价人', 'zhijie')"
        />
        <!-- 间接评价人工号 -->
        <van-field
          :value="
            form.jjsj_empcode ? form.jjsj_empcode + '-' + form.jjsj_empname : ''
          "
          v-if="
            showWeight &&
              usejj_forid == 'true' &&
              $t('jxMyAssTargetDetails.txt11')
          "
          :label="$t('jxMyAssTargetDetails.txt11')"
          readonly
          input-align="right"
          :placeholder="$t('jxMyAssTargetDetails.txt12')"
          @click="showEmpTreePop('间接评价人', 'jianjie')"
        />
        <van-field
          v-model="form.hgyfk"
          :label="'回顾及反馈频率'"
          label-width="7em"
          input-align="right"
        />
        <van-field
          v-model="form.datasupply"
          :label="'数据提供责任人'"
          label-width="7em"
          input-align="right"
        />
        <van-cell
          v-if="$t('jxMyAssTargetDetails.txt10')"
          class="desc_cell"
          :value="$t('jxMyAssTargetDetails.txt10')"
        />

        <van-field
          v-if="$t('jxMyAssTargetDetails.txt10')"
          class="desc_field"
          v-model="form.quotaDesc"
          rows="8"
          autosize
          :readonly="canreadonly"
          type="textarea"
          :placeholder="$t('jxMyAssTargetDetails.txt10')"
        />
        <van-cell class="desc_cell" :value="'备注'" />
        <van-field
          class="desc_field"
          v-model="form.remark"
          rows="8"
          autosize
          type="textarea"
          :placeholder="'备注'"
        />
        <van-cell
          v-if="showWeight"
          class="desc_cell"
          :value="$t('jxMyAssTargetDetails.txt24')"
        />
        <van-field
          v-if="showWeight"
          class="desc_field"
          v-model="form.pfdesp"
          rows="8"
          autosize
          :readonly="canreadonly"
          type="textarea"
          :placeholder="$t('jxMyAssTargetDetails.txt24')"
        />
      </van-form>
    </div>
    <div class="footer" v-if="canSave">
      <van-button type="info" size="small" @click="save">{{
        $t('jxMyAssTargetDetails.txt13')
      }}</van-button>
    </div>

    <!-- 树弹窗 -->
    <van-popup
      class="tree_popup"
      v-model="showTreePop"
      closeable
      close-icon="arrow-left"
      close-icon-position="top-left"
      position="right"
      :style="{ width: '100%', height: '100%' }"
    >
      <van-sticky class="fixed_div">
        <div class="tree_title">
          <span>{{ `请选择${treeTitle}` }}</span>
        </div>
        <div class="tree_search">
          <van-search
            v-model="searchTreeVal"
            placeholder="请输入搜索关键词"
            @search="onSearchTree"
          />
        </div>
        <div class="tree_head">
          <span class="head_start" @click="backTo(true)">开始</span>
          <span
            v-for="(ele, idex) in clickParentTree"
            :key="idex"
            :class="idex == clickParentTree.length - 1 ? 'theLast' : 'noLast'"
            @click="backTo(ele)"
          >
            <span class="division">></span>
            {{ ele.keyname }}
          </span>
        </div>
        <van-divider
          :style="{
            color: '#E8E8E8',
            borderColor: '#E8E8E8',
            margin: '5px 0 0 0'
          }"
        />
      </van-sticky>
      <div class="tree_body">
        <!-- 岗位树和员工树 -->
        <div v-if="whichTree == 'station' || whichTree == 'employee'">
          <!-- 多选树 -->
          <div v-if="multiseltree">
            <!-- 父节点 -->
            <van-cell
              class="parents_cell"
              v-for="(item, index) in parentTreeList"
              :key="index + item.keyname"
              :title="item.keyname"
            >
              <template #default>
                <div
                  :class="item.hasLower ? 'haveClo' : 'noClo'"
                  @click="checkParent(item)"
                >
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
              <template #icon>
                <van-checkbox
                  v-model="item.checked"
                  @click="tickParent(item)"
                  shape="square"
                ></van-checkbox>
              </template>
            </van-cell>
            <!-- 子节点 -->
            <van-cell
              class="childs_cell"
              v-for="(it, idx) in childsTreeList"
              :key="idx"
            >
              <template #title>
                <van-checkbox v-model="it.checked" shape="square">
                  <van-button
                    size="small"
                    :icon="whichTree == 'employee' ? 'user-o' : 'manager-o'"
                    :type="whichTree == 'employee' ? 'warning' : 'primary'"
                  ></van-button>
                  <span class="check_name">{{ it.keyname }}</span>
                </van-checkbox>
              </template>
            </van-cell>
          </div>
          <!-- 非多选 -->
          <div v-else>
            <!-- 父节点 -->
            <van-cell
              class="parents_cell"
              v-for="(item, index) in parentTreeList"
              :key="index + item.keyname"
              :title="item.keyname"
              @click="checkParent(item)"
            >
              <template #default>
                <div :class="item.hasLower ? 'haveClo' : 'noClo'">
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
            <!-- 子节点 -->
            <van-cell
              class="childs_cell"
              v-for="(it, idx) in childsTreeList"
              :key="idx"
              @click="checkChild(it)"
            >
              <template #title>
                <van-button
                  size="small"
                  :icon="whichTree == 'employee' ? 'user-o' : 'manager-o'"
                  :type="whichTree == 'employee' ? 'warning' : 'primary'"
                ></van-button>
                <span class="check_name">{{ it.keyname }}</span>
              </template>
            </van-cell>
          </div>
        </div>
        <!-- 其他树 -->
        <div v-else>
          <!-- 多选树 -->
          <div v-if="multiseltree">
            <van-cell
              class="parents_cell other_cell"
              center
              v-for="(item, index) in otherTreeList"
              :key="index + item.keyname"
            >
              <template #icon>
                <van-checkbox
                  v-model="item.checked"
                  @click="tickParent(item)"
                  shape="square"
                ></van-checkbox>
              </template>
              <template #title>
                <van-button
                  size="small"
                  icon="cluster-o"
                  color="#1491f7"
                ></van-button>
                <span class="check_name">{{ item.keyname }}</span>
              </template>
              <template #default>
                <div
                  v-if="item.hasLower"
                  class="haveClo"
                  @click.stop="hasLower(item)"
                >
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
          </div>
          <!-- 非多选树 -->
          <div v-else>
            <van-cell
              class="parents_cell other_cell"
              center
              v-for="(item, index) in otherTreeList"
              :key="index + item.keyname"
              @click="checkChild(item)"
            >
              <template #title>
                <van-button
                  size="small"
                  icon="cluster-o"
                  color="#1491f7"
                ></van-button>
                <span class="check_name">{{ item.keyname }}</span>
              </template>
              <template #default>
                <div
                  v-if="item.hasLower"
                  class="haveClo"
                  @click.stop="hasLower(item)"
                >
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
      </div>
      <div class="tree_foot" v-if="multiseltree">
        <van-button type="primary" size="small" @click="cleanChecked"
          >清除</van-button
        >
        <van-button
          type="info"
          size="small"
          :disabled="!canComfirm"
          @click="comfirmChecked"
          >确定</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {
  getMy360IndicatorDetail,
  getMyKpiIndicatorDetail,
  saveMy360Indicator,
  addMy360Indicator,
  saveMyKpiIndicator,
  addMyKpiIndicator,
  getMy360IndicatorTypeList,
  getMyKpiIndicatorTypeList,
  getCommTreeData,
  getMyKpiNewDefaultValue,
  getIndicatorPerList,
  getTgValueList,
  getIndicatorList
} from '@api/wxjx.js'
export default {
  data() {
    let mautoid = this.$route.query.mautoid
    let checkflag = this.$route.query.checkflag
    let detaId = this.$route.query.detaId
    let length = this.$route.query.length
    let usejj_forid = this.$route.query.usejj_forid
    let usezj_forid = this.$route.query.usezj_forid
    let isadd = this.$route.query.isadd
    let lastscore = this.$route.query.lastscore
    let khtimeid = this.$route.query.khtimeid
    let req_indicator_select = this.$route.query.req_indicator_select
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      mautoid,
      checkflag,
      detaId,
      length,
      usezj_forid,
      usejj_forid,
      isadd,
      lastscore,
      req_indicator_select,
      khtimeid,
      form: {
        parentTitle: this.$route.query.parentTitle,
        is360: this.$route.query.is360,
        autoid: null,
        sqnum: 1,
        quotaType: '',
        quotaName: '',
        weight: '',
        zjsj_empcode: '',
        jjsj_empcode: '',
        zjsj_empid: null,
        jjsj_empid: null,
        zjsj_empname: '',
        jjsj_empname: '',
        quotaDesc: '',
        score: 0,
        pfdesp: '',
        hgyfk: '',
        datasupply: '',
        remark: ''
      },
      showPicker: false,
      typeColumns: [],
      showTreePop: false, // 树弹窗显示
      treeTitle: '',
      whichTree: 'employee', // 树类型
      searchTreeVal: '', // 树弹窗搜索词
      allTreeList: [], // 所有树集合
      parentTreeList: [], // 上级树集合（针对岗位树和员工数）
      childsTreeList: [], // 下级树集合（针对岗位树和员工数）
      clickParentTree: [], // 储存点击过的父节点
      otherTreeList: [], // 其他类型树集合
      multiseltree: false, // 是否是多选树
      canComfirm: false, // 多选树确定按钮是否可以点击
      toWitchCode: '', // 赋值哪个工号
      canTypeReadonly: true,
      showWeightPicker: false,
      WeightTypeColumns: [],
      showScorePicker: false,
      ScoreTypeColumns: [],
      nameColumns: [],
      showNamePicker: false
    }
  },
  computed: {
    canreadonly() {
      if (this.checkflag == 1 || this.checkflag == 4) {
        return false
      } else {
        return true
      }
    },
    canSave() {
      if (this.checkflag == 1 || this.checkflag == 4) {
        return true
      } else {
        return false
      }
    },
    showWeight() {
      if (this.form.is360 == false || this.form.is360 == 'false') {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    console.log(2222222)
    if (this.detaId) {
      if (this.form.is360 == true || this.form.is360 == 'true') {
        this.getMy360InDetail()
      } else {
        this.getMyKpiInDetail()
      }
    } else {
      this.form.sqnum = this.length
      this.form.quotaType = ''
      this.form.quotaName = ''
      this.form.weight = ''
      this.form.autoid = null
      this.form.quotaDesc = ''
      this.form.pfdesp = ''
      this.form.zjsj_empcode = ''
      this.form.jjsj_empcode = ''
      this.form.zjsj_empid = null
      this.form.jjsj_empid = null
      this.form.zjsj_empname = ''
      this.form.jjsj_empname = ''
      this.form.score = ''
      this.form.hgyfk = ''
      this.form.datasupply = ''
      this.form.remark = ''
      if (this.form.is360 == 'false') {
        this.hqMyKpiNewDefaultValue()
      }
    }
  },
  watch: {
    showTreePop(n) {
      if (n == false) {
        this.parentTreeList = [] // 上级树集合
        this.childsTreeList = [] // 子节点
        this.clickParentTree = [] // 点击过的父节点
        this.otherTreeList = [] // 其他树节点
        this.searchTreeVal = ''
      }
    }
  },
  methods: {
    // 指标权重点击事件
    showWeightPop() {
      if (this.canreadonly) {
        return false
      }
      this.WeightTypeColumns = []
      getIndicatorPerList({ mautoid: this.mautoid }).then(res => {
        let data = res.data
        data.forEach(ele => {
          this.WeightTypeColumns.push(ele.dicvalue)
        })
      })
      this.showWeightPicker = true
    },
    onWeightTypeConfirm(value) {
      this.form.weight = value
      this.showWeightPicker = false
      this.WeightTypeColumns = []
    },

    // // 评分上限/标准分 点击事件
    showScorePop() {
      if (this.canreadonly) {
        return false
      }
      this.ScoreTypeColumns = []
      getTgValueList({ mautoid: this.mautoid }).then(res => {
        let data = res.data
        data.forEach(ele => {
          this.ScoreTypeColumns.push(ele.dicvalue)
        })
      })
      this.showScorePicker = true
    },
    onScoreTypeConfirm(value) {
      this.form.score = value
      this.showScorePicker = false
      this.ScoreTypeColumns = []
    },

    hqMyKpiNewDefaultValue() {
      if (this.isadd == 1) {
        getMyKpiNewDefaultValue({
          mautoid: this.mautoid,
          username: this.userInfo.username
        }).then(res => {
          let data = res.data[0]
          this.form.sqnum = data.sqnum
          this.form.quotaType = data.itemtype
          this.form.quotaName = data.itemname
          this.form.weight = data.itemper
          this.form.score = data.tgvalue
          this.form.quotaDesc = data.itemdesc
          this.form.pfdesp = res.data[0].pfdesp
          this.form.zjsj_empcode = res.data[0].zjsj_empcode
          this.form.jjsj_empcode = res.data[0].jjsj_empcode
          this.form.zjsj_empname = res.data[0].zjsj_empname
          this.form.jjsj_empname = res.data[0].jjsj_empname
          this.form.zjsj_empid = res.data[0].zjsj_empid
          this.form.jjsj_empid = res.data[0].jjsj_empid
          this.form.hgyfk = res.data[0].hgyfk
          this.form.datasupply = res.data[0].datasupply
          this.form.remark = res.data[0].remark
        })
      }
    },

    // 打开树
    showEmpTreePop(val, code) {
      this.toWitchCode = code
      if (!this.canreadonly) {
        this.treeTitle = val
        this.showTreePop = true
        this.getCommTree()
      }
    },

    // 获取通用树
    getCommTree(multiseltree) {
      const editstate = 2
      getCommTreeData({
        moduleno: 503,
        treename: 'emptree', // 树名
        username: this.userInfo.username,
        fieldname: '',
        editstate: editstate,
        formstr: ''
      }).then(res => {
        // 如果是多选树，则返回记录添加是否多选，是否选中属性
        if (multiseltree) {
          res.data.forEach(element => {
            element.multiple = multiseltree
            element.checked = false
          })
        }
        this.allTreeList = res.data
        // 树结构中最小层级(最上层)
        let mini = Math.min.apply(
          Math,
          res.data.map(item => {
            return item.levelid
          })
        )
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          // 岗位和员工树
          // 初始时的父节点
          this.parentTreeList = res.data.filter(item => {
            return item.levelid == mini
          })
          // 判断父节点有没有下级
          if (this.parentTreeList.length > 0) {
            this.parentTreeList.forEach(el => {
              el.hasLower = false
              for (let i = 0; i < this.allTreeList.length; i++) {
                const it = this.allTreeList[i]
                if (el.keyid == it.parentid) {
                  el.hasLower = true
                  break
                }
              }
            })
          }
          // 初始时的子节点
          this.allTreeList.forEach(el => {
            if (this.parentTreeList.length == 1) {
              // 最大父节点只有一个的时候
              let nodecan = false
              if (this.whichTree == 'station') {
                nodecan = el.issta
              } else if (this.whichTree == 'employee') {
                nodecan = el.isdept == 0 && el.levelid == 16
              }
              if (nodecan && el.parentid == this.parentTreeList[0].keyid) {
                this.childsTreeList.push(el)
              }
            } else {
              // 最大父节点有多个的时候
              this.childsTreeList = []
            }
          })
        } else {
          // 其他树(暂时部门树)
          // 初始时的节点
          this.otherTreeList = res.data.filter(item => {
            return item.levelid == mini
          })
          // 判断初始节点有没有下级
          if (this.otherTreeList.length > 0) {
            this.otherTreeList.forEach(el => {
              el.hasLower = false
              for (let i = 0; i < this.allTreeList.length; i++) {
                const it = this.allTreeList[i]
                if (el.keyid == it.parentid) {
                  el.hasLower = true
                  break
                }
              }
            })
          }
        }
      })
    },
    // 搜索子节点
    onSearchTree(val) {
      // 清空父节点、子节点和已点击过的父节点
      this.parentTreeList = []
      this.childsTreeList = []
      this.clickParentTree = []
      this.otherTreeList = []
      this.allTreeList.forEach(el => {
        let nodecan = false
        if (this.whichTree == 'station') {
          nodecan = el.issta
        } else if (this.whichTree == 'employee') {
          nodecan = el.isdept == 0 && el.levelid == 16
        }
        // 岗位和人员
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          if (nodecan && el.keyname.indexOf(val) !== -1) {
            this.childsTreeList.push(el)
          }
        } // 其他（部门）
        else {
          if (el.keyname.indexOf(val) !== -1) {
            // 判断节点有没有下级
            let lowerAry = this.allTreeList.filter(it => {
              return it.parentid == el.keyid
            })
            if (lowerAry.length > 0) {
              el.hasLower = true
            } else {
              el.hasLower = false
            }
            this.otherTreeList.push(el)
          }
        }
      })
    },
    // 回到某个树节点
    backTo(val) {
      if (val === true) {
        // 点击的开始,回到最初始状态
        this.parentTreeList = []
        this.childsTreeList = []
        this.clickParentTree = []
        this.otherTreeList = []
        this.searchTreeVal = ''
        this.allTreeList = []
        this.getCommTree(this.multiseltree)
      } else {
        // 否则先在clickParentTree去掉该父节点及节点后所有节点
        let ary = [] // 记录该节点及后的所有节点
        for (let i = 0; i < this.clickParentTree.length; i++) {
          const ele = this.clickParentTree[i]
          if (val.keyid == ele.keyid) {
            // 如果是数组最后一个则不可以点击，数据不变
            if (i == this.clickParentTree.length - 1) {
              return false
            } else {
              ary = this.clickParentTree.filter((item, index) => {
                return index < i
              })
            }
          }
        }
        this.clickParentTree = ary
        // 去掉之后再区分（岗位和员工）和其他树
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          // 岗位和员工树
          // 再获取该父节点下的子节点
          this.checkParent(val)
        } else {
          // 其他树
          this.hasLower(val)
        }
      }
    },
    // 其他树点击下级
    hasLower(val) {
      // 储存点击过的有下级的父节点
      if (val.hasLower) {
        this.clickParentTree.push(val)
      }
      // 获取此节点下所有子节点
      let array = this.allTreeList.filter(item => {
        return item.parentid == val.keyid
      })
      if (array.length > 0) {
        this.otherTreeList = []
        array.forEach(item => {
          // 判断节点有没有下级
          let lowerAry = this.allTreeList.filter(it => {
            return it.parentid == item.keyid
          })
          if (lowerAry.length > 0) {
            item.hasLower = true
          } else {
            item.hasLower = false
          }
          // 父节点
          this.otherTreeList.push(item)
        })
      }
    },
    // 点击树的父节点(针对岗位和员工树)
    checkParent(val) {
      // 储存点击过的有下级的父节点
      if (val.hasLower) {
        this.clickParentTree.push(val)
      }
      // 获取此父节点下所有节点
      let array = this.allTreeList.filter(item => {
        return item.parentid == val.keyid
      })
      // 遍历所有节点并分出父节点和子节点
      if (array.length > 0) {
        this.parentTreeList = []
        this.childsTreeList = []
        array.forEach(item => {
          let nodecan = false
          if (this.whichTree == 'station') {
            nodecan = item.issta
          } else if (this.whichTree == 'employee') {
            nodecan = item.isdept == 0 && item.levelid == 16
          }
          if (nodecan) {
            // 子节点
            this.childsTreeList.push(item)
          } else {
            // 判断父节点有没有下级
            let lowerAry = this.allTreeList.filter(it => {
              return it.parentid == item.keyid
            })
            if (lowerAry.length > 0) {
              item.hasLower = true
            } else {
              item.hasLower = false
            }
            // 父节点
            this.parentTreeList.push(item)
          }
        })
      }
    },
    // 选择父节点复选框
    tickParent(item) {
      // 判断父节点有没有下级
      let lowerAry = this.allTreeList.filter(it => {
        return it.parentid == item.keyid
      })
      if (lowerAry.length > 0) {
        if (item.checked == true) {
          lowerAry.forEach(ele => {
            this.allTreeList.map(it => {
              if (it.keyid == ele.keyid) {
                it.checked = true
              }
            })
            this.tickParent(ele)
          })
        } else {
          lowerAry.forEach(ele => {
            this.allTreeList.map(it => {
              if (it.keyid == ele.keyid) {
                it.checked = false
              }
            })
            this.tickParent(ele)
          })
        }
      }
    },
    // 多选树清除复选框选中
    cleanChecked() {
      this.allTreeList.forEach(item => {
        item.checked = false
      })
    },
    // 多选树确认复选框选中
    comfirmChecked() {
      let array = this.allTreeList.filter(ele => {
        return ele.checked == true
      })
      let trueList = []
      if (array.length > 0) {
        if (this.whichTree == 'station') {
          for (let i = 0; i < array.length; i++) {
            const it = array[i]
            if (it.issta == true) {
              trueList.push(it)
            }
          }
        } else if (this.whichTree == 'employee') {
          for (let l = 0; l < array.length; l++) {
            const item = array[l]
            if (item.isdept == 0 && item.levelid == 16) {
              trueList.push(item)
            }
          }
        } else {
          // 其他树
          trueList = array
        }
      }
      this.checkChild(trueList)
    },
    // 点击树的子节点(暂时单选树)(针对岗位和员工树)
    checkChild(it) {
      let checked = []
      this.hasEXfieldlist = []
      if (this.multiseltree) {
        checked = it
        //多选树
        const namelist = checked.map(it =>
          it.keyname.indexOf(' ') != -1 ? it.keyname.split(' ')[0] : it.keyname
        )
        const names = namelist.join(',')
        // this.setValue(this.Selectformdata.fieldname, String(names))
        // // 下拉字段带出过程
        // if (this.Selectformdata.isexitvalue) {
        //   this.valuechage(names, this.Selectformdata) //判断数据带出
        // }
      } else {
        checked = [it]
        console.log(it, 'itititit')
        //单选树
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          if (this.toWitchCode == 'zhijie') {
            this.form.zjsj_empcode = it.empcode
            this.form.zjsj_empid = it.empid
            this.form.zjsj_empname = it.empname
          } else {
            this.form.jjsj_empcode = it.empcode
            this.form.jjsj_empid = it.empid
            this.form.jjsj_empname = it.empname
          }
          // this.setValue(
          //   this.Selectformdata.fieldname,
          //   String(
          //     checked[0].keyname.indexOf(' ') != -1
          //       ? checked[0].keyname.split(' ')[0]
          //       : checked[0].keyname
          //   )
          // )
        } else {
          // this.setValue(
          //   this.Selectformdata.fieldname,
          //   String(
          //     checked[0].keyname.indexOf(' ') != -1
          //       ? checked[0].keyname.split(' ')[1]
          //       : checked[0].keyname
          //   )
          // )
        }
      }
      // 最终关闭弹窗
      this.showTreePop = false
    },

    // 7.4.查看某个“360指标方案”的单个指标详细信息接口
    getMy360InDetail() {
      getMy360IndicatorDetail({ autoid: this.detaId }).then(res => {
        if (res.iserror == 0) {
          this.form.sqnum = res.data[0].sqnum
          this.form.quotaType = res.data[0].typename
          this.form.quotaName = res.data[0].indicatorname
          this.form.weight = ''
          this.form.autoid = res.data[0].autoid
          this.form.quotaDesc = res.data[0].indicatordesp.replace(/\\n/g, '\n')
          this.form.score = res.data[0].score
        }
      })
    },
    //  7.11.查看某个“KPI指标方案”的单个指标详细信息接口
    getMyKpiInDetail() {
      getMyKpiIndicatorDetail({ autoid: this.detaId }).then(res => {
        if (res.iserror == 0) {
          this.form.sqnum = res.data[0].sqnum
          this.form.quotaType = res.data[0].itemtype
          this.form.quotaName = res.data[0].itemname
          this.form.weight = res.data[0].itemper
          this.form.autoid = res.data[0].autoid
          this.form.quotaDesc = res.data[0].itemdesc.replace(/\\n/g, '\n')
          this.form.pfdesp = res.data[0].pfdesp.replace(/\\n/g, '\n')
          this.form.zjsj_empcode = res.data[0].zjsj_empcode
          this.form.jjsj_empcode = res.data[0].jjsj_empcode
          this.form.zjsj_empname = res.data[0].zjsj_empname
          this.form.jjsj_empname = res.data[0].jjsj_empname
          this.form.zjsj_empid = res.data[0].zjsj_empid
          this.form.jjsj_empid = res.data[0].jjsj_empid
          this.form.score = res.data[0].tgvalue
          this.form.hgyfk = res.data[0].hgyfk
          this.form.datasupply = res.data[0].datasupply
          this.form.remark = res.data[0].remark
          console.log(res.data[0], 11111111)
        }
      })
    },
    // 360下拉选
    getMy360TypeList() {
      getMy360IndicatorTypeList({ mautoid: this.mautoid }).then(res => {
        res.data.forEach(ele => {
          this.typeColumns.push(ele.typename)
        })
        if (this.typeColumns.length > 0) {
          this.showPicker = true
          this.canTypeReadonly = true
        } else {
          this.canTypeReadonly = false
        }
        // this.typeColumns = res.data.filter(it => { return it.typename })
      })
    },
    // KPI下拉选
    getMyKpiTypeList() {
      getMyKpiIndicatorTypeList({ mautoid: this.mautoid }).then(res => {
        res.data.forEach(ele => {
          this.typeColumns.push(ele.typename)
        })
        if (this.typeColumns.length > 0) {
          this.showPicker = true
          this.canTypeReadonly = true
        } else {
          this.canTypeReadonly = false
        }
        // this.typeColumns = res.data.filter(it => { return it.typename })
      })
    },
    // 指标类别点击事件
    showTypePicker() {
      if (this.canreadonly) {
        return false
      }
      this.typeColumns = []
      if (this.form.is360 == true || this.form.is360 == 'true') {
        this.getMy360TypeList()
      } else {
        this.getMyKpiTypeList()
      }
    },
    // 指标名称点击事件
    showQuotaNamePicker() {
      if (this.canreadonly) {
        return false
      } else {
        if (
          (this.req_indicator_select == 'true' ||
            this.req_indicator_select == true) &&
          (this.form.is360 == false || this.form.is360 == 'false')
        ) {
          if (this.form.quotaType) {
            this.nameColumns = []
            getIndicatorList({
              mautoid: this.mautoid,
              indicatortype: this.form.quotaType,
              empid: this.userInfo.empid
            }).then(res => {
              this.showNamePicker = true
              this.nameColumns = res.data
            })
          } else {
            Toast(this.$t('jxMyAssTargetDetails.txt25'))
          }
        }
      }
    },
    // 指标名称选择器回调
    onNameConfirm(value) {
      this.form.quotaName = value.indicatorname
      this.form.quotaDesc = value.indicatordesc
      this.form.pfdesp = value.pfdesp
      this.showNamePicker = false
      this.nameColumns = []
    },
    //
    onConfirm(value) {
      this.form.quotaType = value
      this.showPicker = false
      this.typeColumns = []
    },
    onClickLeft() {
      // this.$router.push('/jxMyAssTargetApply')
      this.$router.push({
        path: this.$route.query.from
          ? '/' + this.$route.query.from
          : '/jxMyAssTarget',
        query: {
          title: this.$route.query.parentTitle,
          is360: this.$route.query.is360,
          mautoid: this.mautoid,
          checkflag: this.checkflag,
          usejj_forid: this.usejj_forid,
          usezj_forid: this.usezj_forid,
          lastscore: this.lastscore,
          req_indicator_select: this.req_indicator_select,
          khtimeid: this.khtimeid
        }
      })
      // this.$router.push({
      //   path: "/jxMyAssTargetApply",
      //   query: {
      //     title: this.$route.query.parentTitle,
      //     is360: this.$route.query.is360,
      //     mautoid: this.mautoid,
      //     checkflag: this.checkflag,
      //     usejj_forid: this.usejj_forid,
      //     usezj_forid: this.usezj_forid,
      //     lastscore: this.lastscore,
      //     req_indicator_select: this.req_indicator_select
      //   },
      // });
    },
    onSubmit(values) {
      console.log('submit', values)
    },

    // 添加按钮
    save() {
      // this.$router.push('/jxMy360TargetDetails')
      if (this.form.autoid) {
        // 有ID的是修改
        if (this.form.is360 == true || this.form.is360 == 'true') {
          // 360修改
          saveMy360Indicator({
            username: this.userInfo.username,
            autoid: this.form.autoid,
            typename: this.form.quotaType,
            indicatorname: this.form.quotaName,
            indicatordesp: this.form.quotaDesc,
            scoredesp: '',
            score: this.form.score,
            sqnum: this.form.sqnum,
            remark: ''
          }).then(res => {
            if (res.iserror == 0) {
              if (res.data[0].info) {
                Toast(res.data[0].info)
              } else {
                Toast(this.$t('jxMyAssTargetDetails.txt14'))
                this.onClickLeft()
              }
            }
          })
        } else {
          // KPI修改
          // console.log(this.form, "KPI修改");
          saveMyKpiIndicator({
            username: this.userInfo.username,
            autoid: this.form.autoid,
            itemtype: this.form.quotaType,
            itemname: this.form.quotaName,
            itemdesc: this.form.quotaDesc,
            pfdesp: this.form.pfdesp,
            itemper: this.form.weight,
            tgvalue: this.form.score,
            zjsj_empid: this.form.zjsj_empid,
            jjsj_empid: this.form.jjsj_empid,
            sqnum: this.form.sqnum,
            remark: ''
          }).then(res => {
            if (res.iserror == 0) {
              if (res.data[0].info) {
                Toast(res.data[0].info)
              } else {
                Toast(this.$t('jxMyAssTargetDetails.txt14'))
                this.onClickLeft()
              }
            }
          })
        }
      } else {
        // 没有ID的是新增
        if (this.form.is360 == true || this.form.is360 == 'true') {
          // 360新增
          // console.log(this.form, "360新增");
          addMy360Indicator({
            username: this.userInfo.username,
            mautoid: this.mautoid || this.$route.query.mautoid,
            typename: this.form.quotaType,
            indicatorname: this.form.quotaName,
            indicatordesp: this.form.quotaDesc,
            scoredesp: '',
            score: this.form.score,
            sqnum: this.form.sqnum,
            remark: ''
          }).then(res => {
            if (res.iserror == 0) {
              if (res.data[0].info) {
                Toast(res.data[0].info)
              } else {
                Toast(this.$t('jxMyAssTargetDetails.txt15'))
                this.onClickLeft()
              }
            }
          })
        } else {
          // KPI新增
          // console.log(this.form, "KPI新增");
          addMyKpiIndicator({
            username: this.userInfo.username,
            mautoid: this.mautoid || this.$route.query.mautoid,
            itemtype: this.form.quotaType,
            itemname: this.form.quotaName,
            itemdesc: this.form.quotaDesc,
            pfdesp: this.form.pfdesp,
            itemper: this.form.weight,
            tgvalue: this.form.score,
            zjsj_empid: this.form.zjsj_empid,
            jjsj_empid: this.form.jjsj_empid,
            sqnum: this.form.sqnum,
            hgyfk: this.form.hgyfk,
            datasupply: this.form.datasupply,
            remark: this.form.remark
          }).then(res => {
            if (res.iserror == 0) {
              if (res.data[0].info) {
                Toast(res.data[0].info)
              } else {
                Toast(this.$t('jxMyAssTargetDetails.txt15'))
                this.onClickLeft()
              }
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.My360ApplicationDetails {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 192px);
    .van-form {
      /deep/.van-field,
      .van-cell {
        margin: 30px 0;
        .van-cell__title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
        }
        .van-cell__value {
          .van-field__body {
            .van-field__control {
              font-size: 32px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }
          }
        }
      }
      .desc_cell {
        margin-bottom: 0;
        .van-cell__value {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
        }
      }
      .desc_field {
        margin-top: 0;
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 60%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }

  .tree_popup {
    z-index: 10000000 !important;
    .tree_title {
      height: 90px;
      text-align: center;
      span {
        font-size: 36px;
        font-weight: bold;
      }
    }
    .tree_search {
      height: 100px;
    }
    .tree_head {
      height: 90px;
      white-space: nowrap;
      overflow-x: auto;
      display: flex;
      align-items: center;
      span {
        font-size: 32px;
      }
      .theLast {
        color: #969799;
        margin-right: 10px;
      }
      .noLast {
        color: #1491f7;
      }
      .head_start {
        color: #1491f7;
        padding: 0 10px 0 20px;
      }
      .division {
        color: #969799;
        padding: 0 16px;
      }
    }
    .van-divider::before {
      border-width: 20px 0 0;
    }

    .tree_body {
      // height: calc(100% - 5.7rem);  // 多选树时的高度
      height: calc(100% - 314px); // 单选树时的高度
      overflow: auto;
      .parents_cell {
        .van-checkbox {
          padding-right: 10px;
        }
        .van-cell__title {
          flex: 1 1 83%;
        }
        .van-cell__value {
          flex: 1 1 17%;
          div {
            display: flex;
            align-items: center;
            justify-content: end;
            border-left: 1px solid #969799;
          }
          .haveClo {
            color: #1491f7;
          }
          .noClo {
            color: #969799;
          }
          .van-icon {
            padding: 0 10px;
          }
        }
      }
      .childs_cell,
      .other_cell {
        .van-cell__title {
          display: flex;
          align-items: center;
          .van-button {
            border-radius: 12px;
            font-size: 28px;
          }
          .check_name {
            padding-left: 10px;
          }
        }
      }
      // .other_cell {
      //   .van-cell__title {
      //     display: flex;
      //     align-items: center;
      //     .van-button {
      //       border-radius: 12px;
      //       font-size: 28px;
      //     }
      //   }
      // }
    }
    .tree_foot {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #fff;
      display: flex;
      justify-content: space-evenly;
      .van-button {
        width: 30vw;
        margin-bottom: 10px;
      }
    }

    /deep/.van-popup__close-icon--top-left {
      top: 25px !important;
      left: 10px !important;
    }
  }
}
</style>
